import { Backdrop, CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { flatten, uniqBy, includes, debounce } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useQuery } from 'react-apollo';

import { mapCategorySitesQuery, mapConfigQuery } from '../../apollo';
import { MAP_URL } from '../../config';
import { calculateDistance } from '../../helpers/calculateDistance';
import { ReactComponent as HideMap } from '../../images/hide-map.svg';
import pinIcon from '../../images/pin.png';
import { ReactComponent as ShowMap } from '../../images/show-map.svg';

import MapMenu from './MapMenu';
import MapResults from './MapResults';

let map: google.maps.Map;
let infoWindow: google.maps.InfoWindow;
let autocomplete: google.maps.places.Autocomplete;
let marker: google.maps.Marker;
let popup: Popup;
const markers: google.maps.Marker[] = [];
let input: HTMLInputElement;

class Popup extends google.maps.OverlayView {
  position: google.maps.LatLng;
  containerDiv: HTMLDivElement;

  constructor(position: google.maps.LatLng, content: HTMLElement) {
    super();
    this.position = position;

    content.classList.add('popup-bubble');
    const bubbleAnchor = document.createElement('div');
    bubbleAnchor.classList.add('popup-bubble-anchor');
    bubbleAnchor.appendChild(content);
    this.containerDiv = document.createElement('div');
    this.containerDiv.classList.add('popup-container');
    this.containerDiv.appendChild(bubbleAnchor);
    Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
  }

  onAdd() {
    this.getPanes().floatPane.appendChild(this.containerDiv);
  }

  onRemove() {
    if (this.containerDiv.parentElement) {
      this.containerDiv.parentElement.removeChild(this.containerDiv);
    }
  }

  draw() {
    const divPosition = this.getProjection().fromLatLngToDivPixel(
      this.position,
    );

    const display =
      Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
        ? 'block'
        : 'none';

    if (display === 'block') {
      this.containerDiv.style.left = divPosition.x + 'px';
      this.containerDiv.style.top = divPosition.y + 'px';
    }

    if (this.containerDiv.style.display !== display) {
      this.containerDiv.style.display = display;
    }
  }
}

const SiteMap = () => {
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [allSites, setAllSites] = useState<any>([]);
  const [layers, setLayers] = useState<any>([]);
  const [attributes, setAttributes] = useState<any>([]);
  const [selectedSite, setSelectedSite] = useState<any>(null);
  const [mobileView, setMobileView] = useState<boolean>(false);
  const [mobileMapHidden, setMobileMapHidden] = useState<boolean>(true);

  const [position, setPosition] = useState<any>(null);
  const [selectedLayers, setSelectedLayers] = useState<any>([]);
  const [selectedAttributes, setSelectedAttributes] = useState<any>([]);
  const [selectedDays, setSelectedDays] = useState<any>([]);
  const [selectedOpenTime, setSelectedOpenTime] = useState<any>('');
  const [selectedCloseTime, setSelectedCloseTime] = useState<any>('');
  const [nearbySearchText, setNearbySearchText] = useState<string>('');
  const [nearbySearchActive, setNearbySearchActive] = useState<boolean>(false);
  const [locationLoading, setLocationLoading] = useState<boolean>(false);
  const [filterMenuActive, toggleFilterMenu] = useState<boolean>(false);
  const [hourMenuActive, toggleHourMenu] = useState<boolean>(false);

  const query = new URLSearchParams(window.location.search);
  const categories = query
    .get('categories')
    ?.split(',')
    .filter((category) => category);
  const attributesParam = query.get('enableAttributes');
  const availabilityParam = query.get('enableAvailability');
  const enableAttributes = attributesParam !== 'false';
  const enableAvailability = availabilityParam !== 'false';

  const { loading: categorySiteLoading, data } = useQuery(
    mapCategorySitesQuery,
    {
      variables: {
        categories,
      },
    },
  );

  const { loading: configLoading, data: configData } = useQuery(mapConfigQuery);

  const loading = categorySiteLoading || configLoading;

  const center = useMemo(() => {
    if (mobileView) {
      return { lat: 29.7607837, lng: -95.3704939 };
    }

    return { lat: 29.7607837, lng: -95.6004939 };
  }, [mobileView]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 750) {
        setMobileView(false);
      } else {
        setMobileView(true);
      }
    }

    window.addEventListener('resize', handleResize);

    handleResize();
  }, []);

  const centerOnPosition = () => {
    map.panTo(shiftedCoordinates(marker.getPosition(), true));
    map.setZoom(12);
  };

  const resetSearch = () => {
    infoWindow?.close();
    setSelectedSite(null);
    marker.setVisible(false);
    map.panTo(center);
    map.setZoom(10);
    initializeAutocomplete();
  };

  const shiftedCoordinates = useCallback(
    (latlng: any, isUser = false) => {
      const offsetx = mobileView ? 0 : 190;
      const offsety = isUser ? 0 : -100;

      const scale = Math.pow(2, map.getZoom());

      const worldCoordinateCenter = map
        ?.getProjection()
        ?.fromLatLngToPoint(latlng);
      const pixelOffset = new google.maps.Point(
        offsetx / scale || 0,
        offsety / scale || 0,
      );

      if (worldCoordinateCenter) {
        const worldCoordinateNewCenter = new google.maps.Point(
          worldCoordinateCenter.x - pixelOffset.x,
          worldCoordinateCenter.y + pixelOffset.y,
        );

        const shifted = map
          ?.getProjection()
          ?.fromPointToLatLng(worldCoordinateNewCenter);

        return shifted;
      }

      return latlng;
    },
    [mobileView],
  );

  useEffect(() => {
    if (position) {
      popup?.setMap(map);
      popup.position = new google.maps.LatLng(position.coordinates);
      const popupElement = popup.containerDiv.getElementsByClassName(
        'popup-bubble',
      )[0] as HTMLElement;
      popupElement.innerText = position.name;
    } else {
      popup?.setMap(null);
    }
  }, [position]);

  const initializeAutocomplete = useCallback(() => {
    setPosition(null);
    setNearbySearchActive(false);
    setNearbySearchText('');
    input.value = '';
    input.placeholder = 'Enter your location to find partners';
    autocomplete?.unbindAll();
    google.maps?.event?.clearInstanceListeners(input);
    autocomplete = new google.maps.places.Autocomplete(input, {
      componentRestrictions: { country: 'us' },
    });
    autocomplete.bindTo('bounds', map);
    autocomplete.setFields(['geometry', 'name']);
    autocomplete.addListener('place_changed', () => {
      marker.setVisible(false);
      const place = autocomplete.getPlace();

      if (!place?.geometry) {
        window.alert(
          `No details available${place?.name ? ` for '${place?.name}'` : ''}`,
        );
        autocomplete.unbindAll();
        google.maps.event.clearInstanceListeners(input);
        initializeAutocomplete();
        return;
      }

      input.placeholder = `Searching near ${place?.name || 'your location'}`;
      input.value = '';

      const { lat, lng } = place.geometry?.location;
      const position = { lat: lat(), lng: lng() };

      map.setZoom(12);
      marker.setPosition(place.geometry.location);
      map.panTo(shiftedCoordinates(marker.getPosition(), true));
      marker.setVisible(true);
      autocomplete.unbindAll();
      google.maps.event.clearInstanceListeners(input);
      setNearbySearchActive(true);
      setPosition({
        coordinates: position,
        name: place?.name,
      });
      input.focus();
    });
  }, [shiftedCoordinates]);

  const findLocation = () => {
    if (navigator.geolocation) {
      setLocationLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          autocomplete.unbindAll();
          google.maps.event.clearInstanceListeners(input);
          setPosition(null);
          setNearbySearchActive(false);
          setNearbySearchText('');
          marker.setVisible(false);
          input.value = '';
          input.placeholder = 'Searching near your location';
          map.setZoom(12);
          marker.setPosition(pos);
          map.panTo(shiftedCoordinates(marker.getPosition(), true));
          marker.setVisible(true);
          setNearbySearchActive(true);
          setLocationLoading(false);
          setPosition({
            coordinates: pos,
            name: 'Your Location',
          });
          input.focus();
        },
        () => {
          setLocationLoading(false);
          window.alert('Unable to find location.');
          return;
        },
      );
    } else {
      window.alert("Browser doesn't support Geolocation");
      return;
    }
  };

  useEffect(() => {
    infoWindow?.close();

    if (selectedSite) {
      const {
        name,
        layerColor,
        layerName,
        mapAttributes,
        marker,
        locationUrl,
        siteOpen,
        openUntil,
      } = selectedSite;

      const attributeStrings = mapAttributes.map(
        ({ name }: any) =>
          `<div class="map-marker-info-item"><svg width="10" height="11" viewBox="0 0 10 11" fill="none"><path d="M1 7L3.625 10L9.25 1" stroke="#1970A6" strokeLinecap="round" strokeLinejoin="round" /></svg>&nbsp;${name}</div>`,
      );

      const contentString =
        '<div class="map-marker">' +
        `<div class="map-marker-title">${name}</div>` +
        '<div class="map-marker-info-wrapper">' +
        `<div class="map-marker-info-item">` +
        `<svg viewBox="0 0 24 24" width="14" height="14"><path fill=${layerColor} d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" /></svg>&nbsp;` +
        `<div>${layerName}</div>` +
        '</div>' +
        `${attributeStrings.join('')}` +
        '</div>' +
        '<div class="map-marker-info-wrapper last">' +
        `<div>${siteOpen ? 'Open' : 'Closed'}</div>` +
        `<div class="info-dot">${openUntil ? '&nbsp;&sdot;&nbsp;' : ''}</div>` +
        `<div>${openUntil ? openUntil : ''}</div>` +
        '</div>' +
        '<div class="map-marker-share">' +
        `<a href="mailto:?subject=Check out ${name} via Houston Food Bank&body=${encodeURIComponent(
          `${name}\n${locationUrl}\n\nView Houston Food Bank Maps\n${MAP_URL}`,
        )}">share via email</a>` +
        '</div>' +
        '</div>';

      infoWindow = new google.maps.InfoWindow({
        content: contentString,
        maxWidth: 250,
        zIndex: 10,
      });

      infoWindow.open(map, marker);

      const markerPosition = marker.getPosition();
      const shifted = shiftedCoordinates(markerPosition);

      infoWindow.addListener('closeclick', () => setSelectedSite(null));

      map.panTo(shifted);
    }
  }, [selectedSite, shiftedCoordinates]);

  const siteStatus = (mapAvailabilities: any) => {
    if (mapAvailabilities.length) {
      let dayOfWeek = new Date().getDay();
      if (dayOfWeek === 0) {
        dayOfWeek = 6;
      } else {
        dayOfWeek = dayOfWeek - 1;
      }
      const today = new Date();
      const todayDate = today.getDate();
      const todayDay = today.getDay();
      const weekOfMonth = Math.ceil((todayDate + 6 - todayDay) / 7);

      const availability = mapAvailabilities.find(
        ({ day, weeks }: any) =>
          day === dayOfWeek && weeks?.includes(weekOfMonth),
      );

      if (
        availability &&
        !availability?.closed &&
        availability?.openTime &&
        availability?.closeTime &&
        availability?.weeks.length
      ) {
        const currentTime = moment(new Date(), 'HH:mm');

        const siteOpenFormatted = moment
          .utc(availability.openTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
          .format('HH:mm');
        const siteCloseFormatted = moment
          .utc(availability.closeTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
          .format('HH:mm');

        const siteOpenMoment = moment(siteOpenFormatted, 'HH:mm');
        const siteCloseMoment = moment(siteCloseFormatted, 'HH:mm');

        const open = currentTime.isBetween(siteOpenMoment, siteCloseMoment);

        if (open) {
          const timeFormat =
            moment.utc(availability.closeTime).minute() !== 0 ? 'h:mma' : 'ha';

          return {
            siteOpen: open,
            openUntil: `Closes at ${moment
              .utc(availability.closeTime)
              .format(timeFormat)}`,
          };
        }
      }
    }

    return {
      siteOpen: false,
      openUntil: null,
    };
  };

  const initializeLayers = useCallback(() => {
    const categorySiteData = data?.mapCategorySites;

    if (data) {
      let sites = flatten(
        data?.mapCategorySites.map(({ id, name, color, mapSites }: any) => {
          return mapSites.map((mapSite: any) => {
            const {
              name: siteName,
              address1,
              address2,
              city,
              state,
              zip,
            } = mapSite;
            const locationUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURI(
              `${siteName},${address1},${city},${state},${zip}`,
            )}`;
            const locationAddress = `${address1},${
              address2 ? `${address2},` : ''
            } ${city}, ${state}, ${zip}`;
            const normalizedSite = {
              layerId: id,
              layerName: name,
              layerColor: color,
              locationUrl,
              locationAddress,
              ...siteStatus(mapSite.mapAvailabilities),
              ...mapSite,
            };
            return normalizedSite;
          });
        }),
      );

      sites = sites.map((site: any) => {
        const { name, latitude, longitude, layerColor } = site;
        const marker = new google.maps.Marker({
          icon: {
            url:
              'data:image/svg+xml;base64,' +
              btoa(
                `<svg xmlns="http://www.w3.org/2000/svg" focusable="false" viewBox="0 0 24 24" width="24" height="24"><path fill="${layerColor}" stroke="#031A06" stroke-width="1%" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" /></svg>`,
              ),
          },
          map,
          position: { lat: latitude, lng: longitude },
          title: name,
        });

        const siteWithMarker = {
          ...site,
          marker,
        };

        marker.addListener('click', () => setSelectedSite(siteWithMarker));
        markers.push(marker);

        return siteWithMarker;
      });

      const layers = categorySiteData.map(({ id, name, color }: any) => {
        return {
          id,
          name,
          color,
        };
      });

      const siteAttributes = categorySiteData.map(({ mapSites }: any) => {
        return mapSites.map(({ mapAttributes }: any) => {
          return mapAttributes.map(({ id, name }: any) => {
            return {
              id,
              name,
            };
          });
        });
      });

      const attributes = uniqBy(
        flatten(
          flatten(siteAttributes).filter((attribute: any) => attribute.length),
        ),
        'id',
      );

      setLayers(layers);
      setAttributes(attributes);
      setAllSites(sites);
    }

    setInitialLoading(false);
  }, [data]);

  useEffect(() => {
    if (data) {
      map = new google.maps.Map(
        document.getElementById(
          `${mobileView ? 'map-container-mobile' : 'map-container'}`,
        ) as HTMLElement,
        {
          center,
          zoom: 10,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          clickableIcons: false,
          gestureHandling: 'cooperative',
        },
      );

      input = document.getElementById('map-search') as HTMLInputElement;
      marker = new google.maps.Marker({
        map,
        icon: pinIcon,
      });

      if (!popup) {
        popup = new Popup(
          new google.maps.LatLng(center),
          document.getElementById('content') as HTMLElement,
        );
      }

      initializeAutocomplete();

      google.maps.event.addListenerOnce(map, 'tilesloaded', initializeLayers);
    }
  }, [center, data, initializeAutocomplete, initializeLayers, mobileView]);

  const orderedSites = useMemo(() => {
    if (position?.coordinates) {
      const { lat, lng } = position.coordinates;

      const siteWithDistance = allSites.map((site: any) => {
        return {
          ...site,
          distance: calculateDistance(lat, lng, site.latitude, site.longitude),
        };
      });

      return siteWithDistance.sort((a: any, b: any) => {
        return parseFloat(a.distance) - parseFloat(b.distance);
      });
    }

    return allSites.sort((a: any, b: any) => {
      if (a?.name.trim() < b?.name.trim()) {
        return -1;
      }
      if (a?.name.trim() > b?.name.trim()) {
        return 1;
      }
      return 0;
    });
  }, [allSites, position]);

  const filteredSites = useMemo(() => {
    let results = orderedSites;

    if (selectedLayers.length) {
      const selectedLayerIds = selectedLayers.map(({ id }: any) => id);

      results = results.filter((site: any) =>
        includes(selectedLayerIds, site.layerId),
      );
    }

    if (selectedAttributes.length) {
      const selectedAttributeIds = selectedAttributes.map(({ id }: any) => id);

      results = results.filter((site: any) => {
        const siteAttributeIds = site.mapAttributes.map(({ id }: any) => id);

        return siteAttributeIds.some((siteAttributeId: any) =>
          includes(selectedAttributeIds, siteAttributeId),
        );
      });
    }

    if (selectedDays.length) {
      results = results.filter((site: any) => {
        const siteAvailabilityDays = site.mapAvailabilities.map(
          ({ day, closed }: any) => !closed && day,
        );

        return siteAvailabilityDays.some((siteAvailabilityDay: any) =>
          includes(selectedDays, siteAvailabilityDay),
        );
      });

      results = results.map((site: any) => {
        const mapAvailabilitiesFiltered = site.mapAvailabilities.filter(
          ({ day, closed }: any) => !closed && selectedDays.includes(day),
        );

        return {
          ...site,
          mapAvailabilitiesFiltered,
        };
      });
    }

    if (selectedOpenTime) {
      results = results.filter((site: any) => {
        let availabilities =
          site?.mapAvailabilitiesFiltered || site.mapAvailabilities;
        availabilities = availabilities.filter(({ closed }: any) => !closed);

        const siteOpenTimes = availabilities.map(
          ({ openTime }: any) => openTime,
        );

        if (siteOpenTimes.length) {
          return siteOpenTimes.some((siteOpenTime: any) => {
            const selectedMoment = moment(selectedOpenTime, 'HH:mm');
            const siteOpenFormatted = moment
              .utc(siteOpenTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
              .format('HH:mm');
            const siteMoment = moment(siteOpenFormatted, 'HH:mm');

            return selectedMoment.isSameOrAfter(siteMoment, 'hour');
          });
        }

        return false;
      });
    }

    if (selectedCloseTime) {
      results = results.filter((site: any) => {
        let availabilities =
          site?.mapAvailabilitiesFiltered || site.mapAvailabilities;
        availabilities = availabilities.filter(({ closed }: any) => !closed);

        const siteCloseTimes = availabilities.map(
          ({ closeTime }: any) => closeTime,
        );

        if (siteCloseTimes.length) {
          return siteCloseTimes.some((siteCloseTime: any) => {
            const selectedMoment = moment(selectedCloseTime, 'HH:mm');
            const siteCloseFormatted = moment
              .utc(siteCloseTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
              .format('HH:mm');
            const siteMoment = moment(siteCloseFormatted, 'HH:mm');

            return selectedMoment.isSameOrBefore(siteMoment, 'hour');
          });
        }

        return false;
      });
    }

    if (nearbySearchText) {
      const text = nearbySearchText.toLowerCase().split(' ');

      results = results.filter((site: any) => {
        return text.every((t) => {
          return (
            site.name.toLowerCase().indexOf(t) > -1 ||
            site.address1.toLowerCase().indexOf(t) > -1
          );
        });
      });
    }

    return results;
  }, [
    orderedSites,
    nearbySearchText,
    selectedAttributes,
    selectedCloseTime,
    selectedDays,
    selectedLayers,
    selectedOpenTime,
  ]);

  useEffect(() => {
    const filteredSiteMarkers = filteredSites.map(({ marker }: any) => marker);

    markers.forEach((siteMarker: any) => {
      const included = filteredSiteMarkers.includes(siteMarker);

      if (!included) {
        if (infoWindow?.getPosition().equals(siteMarker?.getPosition())) {
          infoWindow?.close();
          setSelectedSite(null);
          siteMarker.setMap(null);
        } else {
          siteMarker.setMap(null);
        }
      } else {
        siteMarker.setMap(map);
      }
    });
  }, [filteredSites]);

  return (
    <>
      {configData?.mapConfig.bannerEnabled && (
        <div className="app-banner">
          <div className="msg-wrapper">
            <span>{configData?.mapConfig.bannerText}</span>
          </div>
        </div>
      )}
      <div
        className={classNames('map-wrapper', {
          banner: configData?.mapConfig.bannerEnabled,
        })}
        onClick={() => {
          toggleHourMenu(false);
          toggleFilterMenu(false);
        }}
      >
        <Backdrop
          open={loading || initialLoading}
          transitionDuration={{ appear: 0, enter: 0, exit: 300 }}
          style={{ zIndex: 99999, backgroundColor: '#fafafa' }}
        >
          <CircularProgress color="primary" />
        </Backdrop>
        <div id="content">Your Location</div>
        {!mobileView && <div id="map-container" />}
        <div className="map-search-container">
          <MapMenu
            resetSearch={resetSearch}
            layers={layers}
            attributes={attributes}
            filterMenuActive={filterMenuActive}
            toggleFilterMenu={toggleFilterMenu}
            hourMenuActive={hourMenuActive}
            toggleHourMenu={toggleHourMenu}
            selectedLayers={selectedLayers}
            setSelectedLayers={setSelectedLayers}
            selectedAttributes={selectedAttributes}
            setSelectedAttributes={setSelectedAttributes}
            updateNearbySearchText={debounce((text) => {
              setNearbySearchText(text);
            }, 300)}
            nearbySearchActive={nearbySearchActive}
            findLocation={findLocation}
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
            selectedOpenTime={selectedOpenTime}
            setSelectedOpenTime={setSelectedOpenTime}
            selectedCloseTime={selectedCloseTime}
            setSelectedCloseTime={setSelectedCloseTime}
            enableAttributes={enableAttributes}
            enableAvailability={enableAvailability}
            locationLoading={locationLoading}
            centerOnPosition={centerOnPosition}
          />
          {mobileView && (
            <>
              <div
                className={classNames('map-wrapper-mobile', {
                  hidden: mobileMapHidden,
                })}
              >
                <div id="map-container-mobile" />
                <div className="map-hide-button">
                  <HideMap onClick={() => setMobileMapHidden(true)} />
                </div>
              </div>
              <div className="map-overlay-hidden">
                <div className="map-show-button">
                  <ShowMap onClick={() => setMobileMapHidden(false)} />
                </div>
              </div>
            </>
          )}
          <MapResults
            filteredSites={filteredSites}
            selectedSite={selectedSite}
            setSelectedSite={setSelectedSite}
            toggleFilterMenu={toggleFilterMenu}
            toggleHourMenu={toggleHourMenu}
          />
        </div>
      </div>
    </>
  );
};

export default SiteMap;
