import { CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';
import { without, concat, difference, findIndex } from 'lodash';
import moment from 'moment';
import React, { FunctionComponent, useMemo } from 'react';

const days = {
  Mon: 0,
  Tue: 1,
  Wed: 2,
  Thu: 3,
  Fri: 4,
  Sat: 5,
  Sun: 6,
} as any;

const MapMenu: FunctionComponent<any> = ({
  resetSearch,
  layers,
  attributes,
  filterMenuActive,
  toggleFilterMenu,
  hourMenuActive,
  toggleHourMenu,
  selectedLayers,
  setSelectedLayers,
  selectedAttributes,
  setSelectedAttributes,
  updateNearbySearchText,
  nearbySearchActive,
  selectedDays,
  setSelectedDays,
  selectedOpenTime,
  setSelectedOpenTime,
  selectedCloseTime,
  setSelectedCloseTime,
  findLocation,
  enableAttributes,
  enableAvailability,
  locationLoading,
  centerOnPosition,
}) => {
  const availabilityHeader = useMemo(() => {
    const formatTime = (time: string) =>
      time ? moment(time, 'HH:mm').format('h:mm a') : 'Any';

    if (selectedOpenTime || selectedCloseTime) {
      return `${formatTime(selectedOpenTime)} - ${formatTime(
        selectedCloseTime,
      )}`;
    }

    return 'Hourly Availabilities';
  }, [selectedCloseTime, selectedOpenTime]);

  const times = useMemo(() => {
    const results = [{ value: '', label: 'Any Time' }];

    for (let i = 5; i <= 22; i++) {
      const timeObj = {
        value: moment(i, 'H').format('HH:mm'),
        label: moment(i, 'H').format('h:mm a'),
      };
      results.push(timeObj);
    }

    return results;
  }, []);

  return (
    <>
      <div className="map-search-form-container">
        <input
          id="map-search"
          className="map-search-input"
          type="text"
          disabled={locationLoading}
          onChange={(e) =>
            nearbySearchActive
              ? updateNearbySearchText(e.target.value)
              : undefined
          }
        />
        {nearbySearchActive ? (
          <div className="map-clear-button-container">
            <div className="map-clear-nearby-button">
              <div className="marker" onClick={centerOnPosition} />
              <div className="clear" onClick={resetSearch} />
            </div>
          </div>
        ) : (
          <>
            <div className="map-search-button-container">
              {locationLoading ? (
                <div className="map-search-loading-container">
                  <CircularProgress size={20} />
                </div>
              ) : (
                <button className="map-search-button" onClick={findLocation} />
              )}
            </div>
            <div className="map-clear-button-container">
              <button
                className="map-clear-button"
                onClick={resetSearch}
                disabled={locationLoading}
              />
            </div>
          </>
        )}
      </div>
      <div className="map-layer-container">
        <div className="map-header-text">Site Type</div>
        <div className="map-layer-button-container">
          {layers.length > 1 &&
            layers.map((layer: any) => {
              const selected = selectedLayers.includes(layer);
              return (
                <button
                  key={layer.id}
                  className={classNames('map-layer-button', {
                    selected,
                  })}
                  onClick={() => {
                    if (selected) {
                      setSelectedLayers(without(selectedLayers, layer));
                    } else {
                      setSelectedLayers(concat(selectedLayers, layer));
                    }
                  }}
                >
                  {layer.name}
                </button>
              );
            })}
          {layers.length === 1 && (
            <button className="map-layer-button selected">
              {layers[0].name}
            </button>
          )}
        </div>
      </div>
      <div className="map-availability-container">
        <div className="map-header-text">Availability</div>
        <div className="map-availability-button-container">
          {Object.keys(days).map((day) => {
            const selected = selectedDays.includes(days[day]);
            return (
              <button
                key={day}
                className={classNames('map-availability-button', {
                  selected,
                })}
                onClick={() => {
                  if (selected) {
                    setSelectedDays(without(selectedDays, days[day]));
                  } else {
                    setSelectedDays(concat(selectedDays, days[day]));
                  }
                }}
              >
                {day}
              </button>
            );
          })}
        </div>
      </div>
      <div
        className="map-picker-container"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="map-picker-button-container">
          {enableAvailability && (
            <div
              className={classNames('map-picker-button availability', {
                active: hourMenuActive,
                hoursActive: selectedOpenTime || selectedCloseTime,
              })}
              onClick={() => {
                toggleFilterMenu(false);
                toggleHourMenu(!hourMenuActive);
              }}
            >
              {availabilityHeader}
            </div>
          )}
          {enableAttributes && (
            <div
              className={classNames('map-picker-button filters', {
                active: filterMenuActive,
              })}
              onClick={() => {
                toggleHourMenu(false);
                toggleFilterMenu(!filterMenuActive);
              }}
            >
              More Filters
            </div>
          )}

          {selectedAttributes.map((attribute: any) => {
            const selected = selectedAttributes.includes(attribute);
            return (
              <div
                key={attribute.id}
                className="map-picker-button selected"
                onClick={() => {
                  if (selected) {
                    setSelectedAttributes(
                      without(selectedAttributes, attribute),
                    );
                  }
                }}
              >
                &nbsp;{attribute.name}
                <ClearIcon />
              </div>
            );
          })}
        </div>
      </div>
      <div
        className="map-dropdown-container"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div
          className={classNames('map-filter-button-container', {
            active: filterMenuActive,
          })}
        >
          {difference(attributes, selectedAttributes).map((attribute: any) => {
            const selected = selectedAttributes.includes(attribute);
            return (
              <div
                key={attribute.id}
                className="map-filter-button"
                onClick={() => {
                  if (!selected) {
                    setSelectedAttributes(
                      concat(selectedAttributes, attribute),
                    );
                  }
                }}
              >
                <AddIcon />
                &nbsp;{attribute.name}
              </div>
            );
          })}
          <div style={{ display: 'flex', width: '100%', padding: '0px 6px' }}>
            <button
              className="map-filter-reset-button"
              onClick={() => setSelectedAttributes([])}
              disabled={!selectedAttributes.length}
            >
              Reset
            </button>
          </div>
        </div>
        <div
          className={classNames('map-availability-dropdown-container', {
            active: hourMenuActive,
          })}
        >
          <div className="map-availability-dropdown-container-header">
            Show me sites that are open between the hours of:
          </div>
          <select
            className="map-availability-dropdown"
            value={selectedOpenTime}
            onChange={(e) => setSelectedOpenTime(e.target.value)}
          >
            {times.map(({ value, label }: any, i) => {
              const closeIndex = findIndex(
                times,
                ({ value }) => value === selectedCloseTime,
              );

              return (
                <option
                  disabled={i !== 0 && closeIndex !== 0 && closeIndex <= i}
                  key={value}
                  value={value}
                >
                  {label}
                </option>
              );
            })}
          </select>
          &nbsp;&mdash;&nbsp;
          <select
            className="map-availability-dropdown"
            value={selectedCloseTime}
            onChange={(e) => setSelectedCloseTime(e.target.value)}
          >
            {times.map(({ value, label }: any, i) => {
              const openIndex = findIndex(
                times,
                ({ value }) => value === selectedOpenTime,
              );

              return (
                <option
                  disabled={i !== 0 && openIndex !== 0 && openIndex >= i}
                  key={value}
                  value={value}
                >
                  {label}
                </option>
              );
            })}
          </select>
          <button
            className="map-filter-reset-button"
            onClick={() => {
              setSelectedOpenTime('');
              setSelectedCloseTime('');
            }}
            disabled={!selectedOpenTime && !selectedCloseTime}
          >
            Reset
          </button>
        </div>
      </div>
    </>
  );
};

export default MapMenu;
